.container {
  display: grid;
  grid-template-columns: [left] 28% [right] 72%;
  grid-template-rows: 15% 30% 40% 15%;
  grid-template-areas: "instrumentSets instruments keyboard";
  width: 800px;
  height: 500px;
  background-color: #beffff;
  border: 3px solid black;
}

.label {
  margin-left: 5px;
  margin-right: 5px;
  font-family: Helvetica, Arial, sans-serif;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.instrument-header {
  align-self: center;
  margin-left: 15px;
  font-family: Helvetica, Arial, sans-serif;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.keyboard-header {
  align-self: center;
  justify-self: center;
  font-family: Helvetica, Arial, sans-serif;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.instrumentSets {
  grid-area: 2 / 1 / 2 / 1;
  justify-self: stretch;
  align-self: start;
  margin-left: 15px;
}

.instruments {
  grid-area: 3 / 1 / 4 / 1;
  justify-self: stretch;
  align-self: start;
  margin-left: 15px;
}

.instrument {
  list-style: none;
  background: #fff;
  border: 1px solid black;
  font-family: Helvetica, Arial, sans-serif;
  color: black;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  padding-left: 4px;
  padding-top: 6px;
  height: 26px;
}

.instrument-selected {
  list-style: none;
  background: #eee;
  border: 1px solid black;
  font-family: Helvetica, Arial, sans-serif;
  color: black;
  font-size: 15px;
  font-weight: bolder;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  padding-left: 4px;
  padding-top: 6px;
  height: 26px;
}

.keyboard {
  grid-area: 2 / 2 / 3 / 2;
  justify-self: center;
  align-self: start;
  margin-block-start: 0;
  padding-inline-start: 0;
  list-style: none;
  border-top: 8px solid black;
  position: relative;
}

.playbackControls {
  grid-area: 4 / 2 / 4 / 2;
  justify-self: center;
  align-self: center;
  margin-left: 15px;
  margin-bottom: 25px;
}

.key-white {
  display: inline-block;
  background: #fff;
  border: 1px solid #444;
  border-radius: 6px;
  margin-top: -4px;
  font-size: 18px;
  font-weight: lighter;
  font-family: Helvetica, Arial, sans-serif;
  height: 280px;
  text-align: center;
  width: 40px;
}

.key-note {
  margin-block-start: 284px;
}